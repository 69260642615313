const paragraphs = document.querySelectorAll('.anim-row p');
const animationDuration = 3000;

function startAnimation() {
  if (paragraphs.length === 0) {
    return; 
  }

  paragraphs.forEach((paragraph, index) => {
    const delay = index * animationDuration;

    setTimeout(() => {
      paragraph.style.animation = 'moveText 3s ease';
      paragraph.parentElement.classList.add('animating');

      setTimeout(() => {
        paragraph.style.animation = 'none';
        paragraph.parentElement.classList.remove('animating');
      }, animationDuration);
    }, delay);
  });

  setTimeout(startAnimation, paragraphs.length * animationDuration);
}

if (paragraphs.length > 0) {
  startAnimation();
}




setInterval(() => {
  const button = document.querySelector('.button-anim');

  if (button) {
    button.classList.add('shake');

    setTimeout(() => {
      button.classList.remove('shake');
    }, 500);
  }
}, 3000);


// document.addEventListener('turbo:load', function () {
//   const anchors = document.querySelectorAll('a[href="#contact-us"]');

//   for (let anchor of anchors) {
//     anchor.addEventListener('click', function (e) {
//       e.preventDefault();
//       const blockID = anchor.getAttribute('href').substr(1);

//       document.getElementById(blockID).scrollIntoView({
//         behavior: 'smooth',
//         block: 'start'
//       });
//     });
//   }
// });






