import { Turbo } from "@hotwired/turbo-rails";
Turbo.session.drive = false;
import "./controllers";
import 'bootstrap/dist/js/bootstrap.bundle'
import $ from 'jquery'
import 'regenerator-runtime/runtime';

require("./packs/swiper")
require("./packs/animation")
require("./packs/services")
require("./packs/header")
require("./packs/special_offer")
require("./packs/hcaptcha")

import "@fortawesome/fontawesome-free/js/all";

const WOW = require('wowjs');

var scrollvalue = 0;
var new_scrollvalue = 0;

$(document).on('turbo:load', function() {
  $(document).ready(function(){
    var wow = new WOW.WOW(
      {
        live: false,
        boxClass:     'wow',      // animated element css class (default is wow)
        animateClass: 'animated', // animation css class (default is animated)
        offset:       0,          // distance to the element when triggering the animation (default is 0)
        mobile:       true,       // trigger animations on mobile devices (default is true)    // act on asynchronously loaded content (default is true)
        callback:     function(box) {
          // the callback is fired every time an animation is started
          // the argument that is passed in is the DOM node being animated
        },
        scrollContainer: null // optional scroll container selector, otherwise use window
      }
    );

    document.wow = wow;
    wow.init();
  });
});

document.addEventListener('turbo:load', function() {
  const anchors = document.querySelectorAll('a[href*="#"]')

  for (let anchor of anchors) {
    anchor.addEventListener('click', function (e) {
      e.preventDefault()
      const blockID = anchor.getAttribute('href').substr(1)

      document.getElementById(blockID).scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    })
  }
})


$(document).on('turbo:load', function () {
  $(document).ready(function () {
    $('button.navbar-toggler').click(function () {
      scrollvalue = $(window).scrollTop();
      $('.navbar-collapse').toggleClass('collapse');

      $(this).toggleClass('active');
    });

    window.copyLink = function copyLink(pathURL) {
      var dummy = document.createElement('input');
      document.body.appendChild(dummy);
      dummy.value = pathURL;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      console.log(dummy.value);
    }

    $(window).scroll(function () {
      if (!$('.navbar-collapse.collapse').hasClass("collapse")) {
        if (window.innerWidth >= 767) {
          if (($(window).scrollTop() - scrollvalue) > 500) {
            new_scrollvalue = $(window).scrollTop();
            $('.navbar-collapse').addClass('collapse');
            $('button.navbar-toggler').removeClass('active');
          }
        } else {
          if (($(window).scrollTop() - scrollvalue) > 350) {
            new_scrollvalue = $(window).scrollTop();
            $('.navbar-collapse').addClass('collapse');
            $('button.navbar-toggler').removeClass('active');
          }
        }
      }
    });
  });
});


$(document).on('turbo:load', function() {
  $('.validateContactform').keyup(function () {
    styleContactSubmitbutton(isContactValid());
  });

  $(document).on("input", "input.contacts_phone", function() {
    if (($('.contacts_phone').val()).length > 9 || ($('.contacts_phone').val()).length == 0) {
      $(".number_phone_warning").removeClass('active');
    } else {
      $(".number_phone_warning").addClass('active');
    }
  });

  function styleContactSubmitbutton(isValidated) {
    let submit_button = document.getElementById("btn-contact");

    if (isValidated) {
      submit_button.disabled = false
      submit_button.style.backgroundColor = "#EE4D4D", submit_button.style.color = "#fff";
    } else {
      submit_button.style.backgroundColor = "#889ca6", submit_button.style.color = "#fff";
      submit_button.disabled = true
    }
  };

  function isContactValid() {
    if(!$('#contact_name').val()) { return }
    if (($('#contact_phone').val()).length < 10) { return }
    if(!$('#contact_message').val()) { return }

    return true;
  };
});

$(document).on('turbo:load', function () {
  $(".dropdown__links").click(function (event) {
    event.stopPropagation();

    var $menu = $(this).find(".dropdown-menu");

    if ($menu.hasClass("d-none")) {
      $(this).find(".dropdown-toggle").addClass("active");
      $menu.removeClass("d-none").addClass("d-block");
    } else {
      $(this).find(".dropdown-toggle").removeClass("active");
      $menu.removeClass("d-block").addClass("d-none");
    }
  });

  $("#language-menu .dropdown-toggle").click(function (event) {
    event.preventDefault();
    event.stopPropagation();

    var $menu = $("#language-menu .dropdown-menu");

    if ($menu.hasClass("d-none") || !$menu.hasClass("show")) {
      $(this).addClass("active");
      $menu.removeClass("d-none").addClass("d-block show");
    } else {
      $(this).removeClass("active");
      $menu.removeClass("d-block show").addClass("d-none");
    }
  });

  $(document).click(function (event) {
    var $target = $(event.target);
    if (!$target.closest('#language-menu').length && $("#language-menu .dropdown-menu").hasClass("d-block")) {
      $("#language-menu .dropdown-toggle").removeClass("active");
      $("#language-menu .dropdown-menu").removeClass("d-block show").addClass("d-none");
    }
  });
});

$(document).on('turbo:load', function() {
  $('.news__icon.copy').click(function() {
    $("#copied-link").show();
    setTimeout(function() { $("#copied-link").hide('fast'); }, 2000);
  });
});

//============CURSOR======================

document.addEventListener('turbo:load', function () {
  const cursorState = localStorage.getItem('cursorState');
  if (cursorState === 'clicked') {
    document.body.classList.add('clicked');
  } else {
    document.body.classList.remove('clicked');
  }

  document.querySelectorAll('body *').forEach(function (element) {
    element.addEventListener('mousedown', function () {
      element.classList.add('clicked');
      localStorage.setItem('cursorState', 'clicked');
    });

    element.addEventListener('mouseup', function () {
      element.classList.remove('clicked');
      localStorage.setItem('cursorState', 'default');
    });
  });
});

window.onload = function() {
  var videoElement = document.getElementById('first-screen-video');
  if (videoElement) {
    var source = document.createElement('source');
    source.src = '/assets/home/intro.mp4';
    source.type = 'video/mp4';
    videoElement.appendChild(source);
    videoElement.load();
  }
};
