import $ from 'jquery';

$(document).on('turbo:load', function () {
  let $popupOverlay = $('.popup-overlay');
  let $requestPopupWrapper = $('.reguest-popup-wrapper');
  let $closeRequestPopup = $('.close-request-popup');
  let $openRequestPopup = $('.open-request-popup');

  if ($popupOverlay.length) {
    function openPopup() {
      $popupOverlay.css('visibility', 'visible');
      $('body').css('overflow', 'hidden');
    }

    function closePopup() {
      $popupOverlay.css('visibility', 'hidden');
      $('body').css('overflow', '');
    }

    $closeRequestPopup.on('click', closePopup);

    $popupOverlay.on('click', function (event) {
      if (!$(event.target).closest($requestPopupWrapper).length) {
        closePopup();
      }
    });

    $openRequestPopup.each(function () {
      $(this).on('click', openPopup);
    });
  }
});
